import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import ConsentRevocation from '../../../components/ConsentRevocation';
import NorwegianBankIdJwtSnippet from '../../../snippets/jwt-payloads/norwegian-bankid';
import NorwegianBankIdBiometricJwtSnippet from '../../../snippets/jwt-payloads/norwegian-bankid-biometric';
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 1,
  "title": "Norwegian BankID",
  "subtitle": "Learn more about Norwegian BankID token contents, how to create test users and how to gain access to production."
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Highlight = makeShortcode("Highlight");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`JWT/Token examples`}</h2>
    <h3>{`Kodebrikke and Mobile authenticators`}</h3>
    <p>{`Triggered with `}<inlineCode parentName="p">{`acr_values=urn:grn:authn:no:bankid`}</inlineCode></p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`The level of assurance for these authenticators are: High`}</p>
    </Highlight>
    <NorwegianBankIdJwtSnippet mdxType="NorwegianBankIdJwtSnippet" />
    <p>{`The `}<inlineCode parentName="p">{`uniqueUserId`}</inlineCode>{` identifies the legal person corresponding to the login, and is not considered sensitive.`}</p>
    <h3>{`Biometric authenticator (BankID app)`}</h3>
    <p>{`Triggered with `}<inlineCode parentName="p">{`acr_values=urn:grn:authn:no:bankid:substantial`}</inlineCode></p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`The level of assurance for this authenticator is: Substantial`}</p>
      <p>{`Note that there is no `}<inlineCode parentName="p">{`certsubject`}</inlineCode>{` returned when using Biometrics.`}</p>
    </Highlight>
    <NorwegianBankIdBiometricJwtSnippet mdxType="NorwegianBankIdBiometricJwtSnippet" />
    <h2>{`Test users`}</h2>
    <p>{`Two types of Norwegian BankID are available:`}</p>
    <ol>
      <li parentName="ol"><em parentName="li">{`Web based BankID with a hardware token`}</em>{`. Also called `}<em parentName="li">{`Netcentric`}</em>{` accounts, test users from this type of BankID may be created and used on the fly.`}</li>
      <li parentName="ol"><em parentName="li">{`Mobile BankID`}</em>{`. Norwegian mobile BankID is based on a so-called `}<em parentName="li">{`SIM card application`}</em>{` which means you need a special SIM card issued by one of the Norwegian carriers.`}</li>
    </ol>
    <h3>{`Creating netcentric test users`}</h3>
    <p>{`Test users are created through the web page at `}<a parentName="p" {...{
        "href": "https://ra-preprod.bankidnorge.no/#/search/endUser"
      }}>{`https://ra-preprod.bankidnorge.no/#/search/endUser`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">{`Go to the `}<a parentName="li" {...{
          "href": "https://ra-preprod.bankidnorge.no/#/generate"
        }}>{`"TEST NUMBER GENERATOR"`}</a>{` to generate a random, valid SSN.`}<br parentName="li"></br>
        <em parentName="li">{`If you want to test `}<a parentName="em" {...{
            "href": "/verify/e-ids/norwegian-bankid/#testing-bankid-biometric"
          }}>{`BankID Biometric`}</a>{`, please make sure that the "Synthetic" checkbox is unchecked before generating a new number. BankID Biometric app does not currently support synthetic SSN numbers, so you won't be able to use them for testing.`}</em></li>
      <li parentName="ol">{`It now says "Could not find any bankIDs for ...".`}</li>
      <li parentName="ol">{`Fill out the first name, last name, and BankID friendly name.`}</li>
      <li parentName="ol">{`Click "Order" to initiate the process.`}</li>
      <li parentName="ol">{`Click the pencil icon and add a phone number and an email that you want to associate with the test user.`}<br parentName="li"></br>
        <em parentName="li">{`You can use any values that match the correct email and phone number formats (note that the number of digits will differ per country). Random values are acceptable as you'll be able to access the one-time codes via URLs, as shown in steps 5 and 11 of the `}<a parentName="em" {...{
            "href": "/verify/e-ids/norwegian-bankid/#testing-bankid-biometric"
          }}>{`Testing BankID Biometric section`}</a>{`.`}</em></li>
      <li parentName="ol">{`Once the process is complete, you will have a test user. User name is the generated SSN, one time password (OTP) is always "otp", and password is always "qwer1234".`}</li>
    </ol>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`It can take up to 1 hour before a newly created test user is activated.`}</p>
    </Highlight>
    <p>{`You can test it out at `}<a parentName="p" {...{
        "href": "https://verify-login.azurewebsites.net"
      }}>{`our authentication demo site`}</a>{`, which is a small sample hosted by Criipto.`}</p>
    <h3>{`Renewing netcentric test users`}</h3>
    <p>{`If you run into issues with test users created earlier (e.g. errors when entering the one time password), your test user certificate might have expired.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "42.708333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAABo0lEQVQoz22SzW7bMBCEde7r9tJLX6LnvkeA+pZDAzSuowiOYVtxJDuurR9aiS2JpH6oryBdNEHrBQbLIXa54Ox4cRwTRRFhGGLPSZK4vF6v2WyeyfMcrTVKqf/QNA1ZlrFarVxPngs8+8B294v9fk+WphRF4YrsOctSjscjXdfRNg1t2zo0Wp+H6IbjqaQoXjgcDq7WM8NAJyWqrqmUopLyDCUp7Z2UvJ5OnKrK4ViWaDug62DogAEGQ9/3bojX9h2v4zvi7zc83t/zFAQ8BgHhZML89pb1dMpiPCYKAla+Tzi5Q6cJL7uE65VgtMiJRQWmRyqNhyzxpxHfHlIwHUZKUAr+5OE9lxJTW17j/1zw4dMI7+OIz1dLbJS1wusbzT56xp88kIYhMsvQdhG5OGch3nGBynP6w4F0s+PrzYYv1zE/4sJ9W0qFh1NhoNHKaTQA/TDQ9j2dMRfRGuN63sI4TbX98na7RQhBkqQIkWN5WZYYY/5u8yKUopZ2icppZy1U1xJvNpsxn8+ZWvEXCyy3nloul85CtllaF1zw4b+oqprf/vynLYa4rbUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "BankID test user error",
          "title": "BankID test user error",
          "src": "/static/dd86462b80a955ae224d8be4e30d506c/e5715/no-bank-id-error.png",
          "srcSet": ["/static/dd86462b80a955ae224d8be4e30d506c/8514f/no-bank-id-error.png 192w", "/static/dd86462b80a955ae224d8be4e30d506c/804b2/no-bank-id-error.png 384w", "/static/dd86462b80a955ae224d8be4e30d506c/e5715/no-bank-id-error.png 768w", "/static/dd86462b80a955ae224d8be4e30d506c/ae694/no-bank-id-error.png 850w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`This can be fixed by ordering a new netcentric BankID:`}</p>
    <ul>
      <li parentName="ul">{`Find your test user by entering its Personal ID Number number at `}<a parentName="li" {...{
          "href": "https://ra-preprod.bankidnorge.no/#/search/endUser"
        }}>{`https://ra-preprod.bankidnorge.no/#/search/endUser`}</a></li>
      <li parentName="ul">{`Fill in the form to order the new netcentric BankID, choosing BankID TestBank in HA Services, and click "Order"`}</li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "44.79166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA3UlEQVQoz5WQy0rEQBBF8+9+g2t/wYVLQRD3rkQEcTcy4xiSMenu6ueRakyU0Qyx4NJV/Tjcvk1vA9sPx+tB2Hcjm87SGWF3sBgfoRRyzqvViLOk4IHC7yqUsl4VaIzBWktK6euAf0Emac1AVYxx1lKtAg7DQNu2iAghhCp1O8F/9quAIr7CjsNV0HF/yvEMDDGQSUgQrLGoY81UNY4jU8ba67rfvtN3fZ3/BO42b1xf3vJw/0TKCe/9SZcxRFJcdtw8P75wfnbBzdVd3dTvK3RJmrEP37Pen9445/gETEnBxSANsY8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Order new BankID",
          "title": "Order new BankID",
          "src": "/static/f83eea65fd97da2a28249ab74c9234d5/e5715/no-bank-id-order-new-bankid.png",
          "srcSet": ["/static/f83eea65fd97da2a28249ab74c9234d5/8514f/no-bank-id-order-new-bankid.png 192w", "/static/f83eea65fd97da2a28249ab74c9234d5/804b2/no-bank-id-order-new-bankid.png 384w", "/static/f83eea65fd97da2a28249ab74c9234d5/e5715/no-bank-id-order-new-bankid.png 768w", "/static/f83eea65fd97da2a28249ab74c9234d5/4ad3a/no-bank-id-order-new-bankid.png 1152w", "/static/f83eea65fd97da2a28249ab74c9234d5/71c1d/no-bank-id-order-new-bankid.png 1536w", "/static/f83eea65fd97da2a28249ab74c9234d5/26162/no-bank-id-order-new-bankid.png 2020w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`Testing BankID Biometric`}</h3>
    <p>{`Start by creating a netcentric test user as described `}<a parentName="p" {...{
        "href": "/verify/e-ids/norwegian-bankid/#creating-netcentric-test-users"
      }}>{`above`}</a>{`.`}</p>
    <p>{`You will then need to install the test version of the BankID App. Contact our `}<a parentName="p" {...{
        "href": "mailto:support@criipto.com"
      }}>{`support team`}</a>{` to get access to the `}<inlineCode parentName="p">{`iOS`}</inlineCode>{` (distributed via `}<inlineCode parentName="p">{`TestFlight`}</inlineCode>{`) or `}<inlineCode parentName="p">{`Android`}</inlineCode>{` version of the test app. In your request, please provide the email address linked to your Apple ID (for `}<inlineCode parentName="p">{`iOS`}</inlineCode>{`) or your Google account for the Play Store (for `}<inlineCode parentName="p">{`Android`}</inlineCode>{`) used for testing. Our team will get back to you with confirmation and download links for the test app.`}</p>
    <p>{`The app must be activated before first use:`}</p>
    <ol>
      <li parentName="ol">{`Press the `}<strong parentName="li">{`Activate BankID app`}</strong>{` button`}</li>
      <li parentName="ol">{`Press the `}<strong parentName="li">{`Activate`}</strong>{` button`}</li>
      <li parentName="ol">{`Enter the birth number (SSN) for the test user you created and press the `}<strong parentName="li">{`Next`}</strong>{` button`}</li>
      <li parentName="ol">{`Enter the phone number you associated to the test user and press the `}<strong parentName="li">{`Next`}</strong>{` button`}</li>
      <li parentName="ol">{`Open the following page to get your your SMS one-time code (after replacing the `}<inlineCode parentName="li">{`{yourTestSsn}`}</inlineCode>{` placeholder with your actual test-SSN): `}<a parentName="li" {...{
          "href": "https://toba-preprod.bankidapis.no/test-events/sms-sent-to-qualified-user/%7ByourTestSsn%7D"
        }}>{`https://toba-preprod.bankidapis.no/test-events/sms-sent-to-qualified-user/{yourTestSsn}`}</a><br parentName="li"></br>
        <em parentName="li">{`Note: The page might need to be refreshed several times, and if multiple activations are done in a short period, old SMS codes may be shown. Only the newest activation code will at any point be valid`}</em></li>
      <li parentName="ol">{`Type the one-time code that is shown on the website into the app and press the `}<strong parentName="li">{`Next`}</strong>{` button`}</li>
      <li parentName="ol">{`Select `}<strong parentName="li">{`Alternative activation`}</strong>{` in the next page`}</li>
      <li parentName="ol">{`Press the `}<strong parentName="li">{`Get activation codes`}</strong>{` button`}</li>
      <li parentName="ol">{`Proceed past the information pages that explain `}<strong parentName="li">{`What you need`}</strong>{` and the `}<strong parentName="li">{`Steps to activate`}</strong>{` with the chosen method`}</li>
      <li parentName="ol">{`Press the `}<strong parentName="li">{`Send email`}</strong>{` button in the "Is this your email?” screen`}</li>
      <li parentName="ol">{`Open the following page to get your email activation codes (once again, replace the {yourTestSsn} placeholder with your test-SSN) `}<a parentName="li" {...{
          "href": "https://ra-preprod.bankidnorge.no/notification-console/content/%7ByourTestSsn%7D"
        }}>{`https://ra-preprod.bankidnorge.no/notification-console/content/{yourTestSsn}`}</a></li>
      <li parentName="ol">{`Type the code words that are shown on the website into the app and press the `}<strong parentName="li">{`Next`}</strong>{` button`}</li>
      <li parentName="ol">{`Press the `}<strong parentName="li">{`Next`}</strong>{` button in the "Log in using BankID to complete the activation” screen`}</li>
      <li parentName="ol">{`Wait until BankID client has loaded in the browser. Pick `}<strong parentName="li">{`BankID app`}</strong>{` in the method list if it is not already preselected.`}</li>
      <li parentName="ol">{`Type "qwer1234” as personal password and click the blue arrow button to the right`}</li>
      <li parentName="ol">{`A progress bar will appear and when finished you will be activated`}</li>
      <li parentName="ol">{`Enable biometrics (which will take you through a flow where you must agree to the terms and conditions)`}</li>
      <li parentName="ol">{`Run your first biometrics-based login`}</li>
    </ol>
    <h3>{`Testing Mobile BankID`}</h3>
    <p>{`For testing you may order up to three test SIM cards through Criipto once you have signed up for Norwegian BankID.`}</p>
    <h2>{`Data and consent for Norwegian BankID`}</h2>
    <h3>{`Available data / scopes`}</h3>
    <p>{`Basic user information, full name, and date of birth are always made available. Additional data may be requested and is released with explicit user consent only.`}</p>
    <p>{`For applications configured to use a `}<inlineCode parentName="p">{`dynamic`}</inlineCode>{` `}<inlineCode parentName="p">{`scope`}</inlineCode>{` strategy, the following `}<inlineCode parentName="p">{`scope`}</inlineCode>{` tokens can be supplied: `}<inlineCode parentName="p">{`address`}</inlineCode>{`, `}<inlineCode parentName="p">{`email`}</inlineCode>{`, `}<inlineCode parentName="p">{`phone`}</inlineCode>{` and `}<inlineCode parentName="p">{`ssn`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Data type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Released`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Verified`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`scope`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`login_hint`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Full name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Always`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of birth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Always`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SSN ("fødselsnummer" in Norwegian)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`User consent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ssn`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope:ssn`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`With user consent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`address`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope:address`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`With user consent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope:email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`With user consent`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`phone`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`scope:phone`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h4>{`Example (partial) authorize request with scopes`}</h4>
    <pre><code parentName="pre" {...{}}>{`https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?scope=openid email address&...
`}</code></pre>
    <p>{`Alternatively, you can send them in the `}<inlineCode parentName="p">{`login_hint`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`https://YOUR_SUBDOMAIN.criipto.id/oauth2/authorize?...&login_hint=scope:email scope:address&...
`}</code></pre>
    <p>{`which can be a useful if you are working with technology that does not let you control the `}<inlineCode parentName="p">{`scope`}</inlineCode>{` value.`}</p>
    <p>{`Access to the SSN is governed by Norwegian law, as described in the `}<a parentName="p" {...{
        "href": "/eid-specifics/order-no-bankid"
      }}>{`Ordering Norwegian BankID`}</a>{` guide.`}</p>
    <p>{`The unverified data are supplied by end-users and not verified by Bidbax (operator of BankID) or the Norwegian banks.`}</p>
    <h3>{`Consent model`}</h3>
    <p>{`End-users must explicitly grant consent to releasing the data to you.`}</p>
    <p>{`The consent model is enforced by Bidbax, and they also provide the consent and data collection dialogs.`}</p>
    <h3>{`Forced and optional consent`}</h3>
    <p>{`If you request SSN, it will be treated as a required value. End users will not be allowed to complete a login until they have explicitly given their consent to release SSN.`}</p>
    <p>{`All other additional data are treated as optional values. A login may complete even if the user does not consent to release the requested data.`}</p>
    <p>{`Consent to SSN will be stored by Criipto for 1 year for your tenant, after which the user must provide explicit SSN consent again.`}</p>
    <Highlight icon="info" mdxType="Highlight">
      <p>{`Criipto `}<em parentName="p">{`does not`}</em>{` store the SSN itself, just the fact that the user has granted your tenant access to it.`}</p>
    </Highlight>
    <ConsentRevocation mdxType="ConsentRevocation" />
    <h3>{`Configuration`}</h3>
    <p>{`You can use the Criipto management dashboard `}<a parentName="p" {...{
        "href": "https://dashboard.criipto.com/providers/NO_BANKID"
      }}>{`dashboard.criipto.com`}</a>{` to configure access to the optional user data, in the `}<strong parentName="p">{`Identity Sources`}</strong>{` -> `}<strong parentName="p">{`NO BankID`}</strong>{` section.`}</p>
    <h2>{`Ordering Norwegian BankID`}</h2>
    <p>{`To start accepting real users with Norwegian BankID, you must first request your `}<em parentName="p">{`client credentials`}</em>{` from Bidbax. The credentials consists of a `}<em parentName="p">{`client id`}</em>{` and a `}<em parentName="p">{`client secret`}</em>{`.`}</p>
    <Highlight icon="file-lines" mdxType="Highlight">
      <p><strong parentName="p">{`Prerequisites for ordering`}</strong></p>
      <p>{`In order to apply for the BankID client credentials for a company you must meet the basic requirements:`}</p>
      <ul>
        <li parentName="ul">{`Your company must be a customer of a Norwegian Bank. Most banks in Norway are part of the BankID network.`}</li>
        <li parentName="ul">{`The person that will sign the contract must be in possession of one of these personal eIDs: Norwegian BankID, Swedish BankID, or Danish MitID.`}</li>
        <li parentName="ul">{`You must have completed step 5 in the `}<a parentName="li" {...{
            "href": "/verify/guides/production"
          }}>{`Getting ready for production`}</a>{` guide. You will need the production domain to complete the order for your client credentials.`}</li>
      </ul>
    </Highlight>
    <h3>{`New customers`}</h3>
    <p>{`The BankID OIDC Biometrics option is now available when ordering BankID.`}</p>
    <p>{`Biometrics is always included in the basis agreement.`}</p>
    <h3>{`Existing customers`}</h3>
    <p>{`Due to new terms and conditions in the agreement, you must send us an updated application if you wish to use BankID Biometrics.`}</p>
    <h3>{`BankID Biometrics assurance level`}</h3>
    <p>{`BankID Biometrics assurance level is “Substantial”.`}</p>
    <p><a parentName="p" {...{
        "href": "https://ec.europa.eu/digital-building-blocks/wikis/display/DIGITAL/eIDAS+Levels+of+Assurance"
      }}>{`Learn more about assurance levels`}</a>{`.`}</p>
    <p>{`If you can only use assurance level “High” in your login flow, you must explicitly specify the `}<a parentName="p" {...{
        "href": "/verify/guides/prefilled-fields/"
      }}>{`login_hint=BID`}</a>{` in your authorize requests to Criipto Verify. `}<em parentName="p">{`Please note that using `}<inlineCode parentName="em">{`login_hint=BID`}</inlineCode>{` will disable biometrics in the BankID app.`}</em>{`  `}</p>
    <h3>{`Modifying user flows`}</h3>
    <p>{`By default, the user flow is controlled by the user-defined settings of the BankID app: no additional configurations required. If necessary, you can adjust the user flow by adding a `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` to the `}<a parentName="p" {...{
        "href": "/verify/guides/authorize-url-builder/"
      }}>{`authorize URL`}</a>{`.`}</p>
    <p>{`The table below provides a list of options for using `}<inlineCode parentName="p">{`login_hint`}</inlineCode>{` with Norwegian BankID, and the resulting user flows. Check `}<a parentName="p" {...{
        "href": "https://docs.criipto.com/verify/guides/prefilled-fields/"
      }}>{`our guide on prefilled fields`}</a>{` to learn more.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`login_hint=BID`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user will be redirected to the BankID app, with biometrics disabled. The user will be queried for userID(i.e. national identity number) in the first dialogue.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`login_hint=BID:<SSN>`}</inlineCode>{`, where `}<inlineCode parentName="td">{`<SSN>`}</inlineCode>{` has the format `}<inlineCode parentName="td">{`DDMMYYXXXXX`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user will be redirected to the BankID app (with biometrics disabled) along with a pre-selected userID. The userID dialogue is omitted in this case.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`login_hint=BIS`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user will be redirected to the BankID app. The user will be queried for userID in the first dialogue. This option does not disable biometrics.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`login_hint=BIS:<SSN>`}</inlineCode>{`, where `}<inlineCode parentName="td">{`<SSN>`}</inlineCode>{` has the format `}<inlineCode parentName="td">{`DDMMYYXXXXX`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The user will be redirected to the BankID app with a pre-selected userID. This option does not disable biometrics.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`no `}<inlineCode parentName="td">{`login_hint`}</inlineCode>{` provided`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The default settings of the BankID app on the user's device will determine the flow (whether biometrics are enabled or disabled).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Ordering the client credentials`}</h3>
    <p>{`To order production credentials please send a request to`}</p>
    <p style={{
      "textIndent": "50px"
    }}><a href="mailto:support@criipto.com?subject=NO BankID for ...">support@criipto.com</a></p>
    <p>{`with answers to these questions:`}</p>
    <ol>
      <li parentName="ol">{`A short description of what your application does and why it needs BankID.`}</li>
      <li parentName="ol">{`Your company: `}<em parentName="li">{`Name, organisation number, and address`}</em></li>
      <li parentName="ol">{`General contact person at your company for BankID related communication: `}<em parentName="li">{`Name, mobile phone, and email`}</em></li>
      <li parentName="ol">{`Person registered in the business registry with authorization to sign the agreement: `}<em parentName="li">{`Name, mobile phone and email`}</em></li>
      <li parentName="ol">{`Your company’s Norwegian bank: `}<em parentName="li">{`Name, organisation number, and address`}</em></li>
      <li parentName="ol">{`Contact person with authorization to receive the client credentials and client secret: `}<em parentName="li">{`Name, mobile phone, and email`}</em></li>
      <li parentName="ol">{`Contact person with authorization to block/revoke the use of BankID: `}<em parentName="li">{`Name, mobile phone, and email`}</em></li>
      <li parentName="ol">{`The display name to appear in the login app. E.g. the name of your company or your specific service. (See the image below)`}</li>
      <li parentName="ol">{`The URL of your production domain as setup in step 4 of the `}<a parentName="li" {...{
          "href": "/verify/guides/production"
        }}>{`Getting ready for production`}</a></li>
      <li parentName="ol">{`If you need access to social security numbers (“fødselsnummer”) please provide a thorough explanation of why and reference the Norwegian law and paragraph that grants you the right to receive them. If SSN is needed, then the reference to the Norwegian Law and Paragraph is mandatory.`}</li>
      <li parentName="ol">{`Finally - if you are not a Norwegian company - you must enclose a company certificate from the official business registry of the country of incoporation.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "555px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "31.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVQY02WR3U7CQBCF+4y+ia/gU+gb+AJeeeGlRIWIKEoKVKCUbbe/0KW73cJntjcmOsnJZCaZM+fMeE3TIKVECEEcx2RZRpIkpGlKVZZ0tqVt/0NrgzYtbt7NOQ5jDJ5SiqIo+kaWSoLFnK/ZDN/3WQYBZX3EaI3+A+iAE63RJFKSZ1nf99w223Wcz2fi8sjjdIW/2rIIBYswZpsr8lqTH35RKs31w5LL2zfmYo+L1lqstXg9mW3BtoTpgftxiEwLVGM5NJbKnCl0R+ly01HoE3tz4urO5+JmxHtY9WrdCZw4r94EROMRcvZB8jlh+Txg9TqiXn9TrwPqKESJHSpco2KB6usIKgmVoDvWaGN6uz3hfjrm+3nA5nVI8DJg8fTIdjxETCfo+SddtMLmKXa3wWYJNo2xSYROBI2IMKru7bqHOMs/ETTDp8dtOX8AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "BankID login",
          "title": "BankID login",
          "src": "/static/34d74dd2c6933482be965ff73e5bd191/cd039/no-bankid-central.png",
          "srcSet": ["/static/34d74dd2c6933482be965ff73e5bd191/8514f/no-bankid-central.png 192w", "/static/34d74dd2c6933482be965ff73e5bd191/804b2/no-bankid-central.png 384w", "/static/34d74dd2c6933482be965ff73e5bd191/cd039/no-bankid-central.png 555w"],
          "sizes": "(max-width: 555px) 100vw, 555px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`Next steps`}</h3>
    <p>{`After Criipto has received the above information, we order the client credntials from your bank by filling out an online agreement, which is then sent to the appointed persons at your company for signing. Criipto will also sign the agreement`}</p>
    <p>{`When all signatures are in place the signed agreement is sent to your bank for further processing and eventual issuance of your client credentials.`}</p>
    <p>{`Once you have received credentials, they must be entered into the Criipto Verify management UI to configure your NO BankID integration.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      